import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import museum from './svg/Asset 15.svg'
import museumline from './svg/Asset 16.svg'

function Museum() {
  return (
    <span className='museum'>
    	<ScrollAnimation animateIn="fadeInLeft" delay="500">
            <h2>Cleveland Museum of Natural History</h2>
            <span className='cyancdesign'>cyancdesign</span>
    		<img className='image' src={museum} alt="Cleveland Museum of Natural History" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={museumline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="right">
                <div class="text-content">
                <iframe src="https://player.vimeo.com/video/47463971?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&background=1&autopause=false" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <h3>Displays</h3>
                    <ul>
                        <li>CV Multitouch Table</li>
                        <li>Mythic Matching Game</li>
                        <li>Volcano Mixing Display</li>
                        <li>Earthquake Machine Interface</li>
                    </ul>
                </div>
                <i></i>
            </div>
        </div>
    </span>
  );
}

export default Museum;
