import React from 'react';

import './Timeline.scss';
import MainLine from './MainLine.jsx'
import KentState from './KentState.jsx'
import Optiem from './Optiem.jsx'
import Learning from './Learning.jsx'
import Nuigroup from './Nuigroup.jsx'
import Graffiti from './Graffiti.jsx'
import Onosysone from './Onosysone.jsx'
import Ripleys from './Ripleys.jsx'
import Museum from './Museum.jsx'
import Onosystwo from './Onosystwo.jsx'
import Health from './Health.jsx'
import Scoutone from './Scoutone.jsx'
import Scouttwo from './Scouttwo.jsx'
import Workday from './Workday.jsx'

class Timeline extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'Chris Yanc: Career Timeline';
  }

  render() {
    return (
      <div className="Timeline">
        <MainLine />
        <KentState />
        <Optiem />
        <Learning />
        <Nuigroup />
        <Graffiti />
        <Onosysone />
        <Ripleys />
        <Museum />
        <Onosystwo />
        <Health />
        <Scoutone />
        <Scouttwo />
        <Workday />
      </div>
    );
  }
}

export default Timeline;
