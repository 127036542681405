import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import workday from './svg/Asset 25.svg'
import workdayline from './svg/Asset 26.svg'

function Workday() {
  return (
    <span className='workday'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
    		<img className='image' src={workday} alt="Workday Logo" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={workdayline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                    <h3>Responsibilities</h3>
                    <span>Senior UX Engineer</span>
                    <ul>
                        <li>Product Design</li>
                        <li>Design System Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
  );
}

export default Workday;
