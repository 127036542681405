import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import kentstate from './svg/Asset 01.svg'
import kentstateline from './svg/Asset 02.svg'

function KentState() {
  return (
    <div className='kentstate'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
            <span>
                <h2>Visual Communication Design</h2>
                <img className='ksulogo' src="https://s3.amazonaws.com/undeniable.kent.edu/files/kent_state+Horizontal_RGB.png" alt="Kent State University Logo" />
        		<img className='image' src={kentstate} alt="Backpack" />
            </span>
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={kentstateline} alt="Connector Line" />
    	</ScrollAnimation>
        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                    <h3>Education</h3>
                    <ul>
                        <li>Graphic Design</li>
                        <li>Web Design &amp; Development</li>
                        <li>Illustration</li>
                        <li>Video Editing &amp; Animation</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
  );
}

export default KentState;
