import React from 'react';

import './Hometoy.scss';


class Hometoy extends React.Component {

  constructor(props) {
    super(props);
    this.myCanvasHolder = React.createRef();
    this.myCanvas = React.createRef();
    this.state = {
      message: "Mouse Event",
      canvas: null,
      ctx: null,
      // canvasOffset: null,
      offsetX: 0,
      offsetY: 0,
      storedLines: [],
      startX: 0,
      startY: 0,
      isDown: false,
    };
  }

  handleEvent = (event) => {
   if (event.type === "mousedown") {
      this.handleMouseDown(event)
    } else if (event.type === "mousemove") {
      this.handleMouseMove(event)
    } else {
      this.handleMouseUp(event)
    }
  }

  componentDidMount() {
    document.title = 'Website of Chris Yanc, Interactive Designer & Developer';

    console.log(this.myCanvas)
    console.log(this.myCanvas.current)
    console.log(this.myCanvas.current.getContext("2d"))

    let _canvas = this.myCanvas.current;
    let _ctx = _canvas.getContext("2d");

    _ctx = _canvas.getContext("2d");
    // _ctx = this.drawSupportDots(_ctx)
    // _ctx.beginPath();
    // _ctx.rect(20, 20, 150, 100);
    // _ctx.stroke();

    _ctx.strokeStyle = "orange";
    _ctx.lineWidth = 3;

    this.setState({
      ...this.state,
      canvas: _canvas,
      ctx: _ctx,
      offsetY: this.myCanvasHolder.current.offsetTop,
      offsetX: _canvas.offsetLeft,
      // canvasOffset: _canvas.offset(),
    })
  }

  drawSupportDots = (ctx) => {
    let _ctx = ctx

    _ctx = this.drawCircle(_ctx, {x: 453, y: 154, r: 10});
    _ctx = this.drawCircle(_ctx, {x: 624, y: 140, r: 10});
    _ctx = this.drawCircle(_ctx, {x: 778, y: 154, r: 10});

    _ctx = this.drawCircle(_ctx, {x: 453, y: 345, r: 10});
    _ctx = this.drawCircle(_ctx, {x: 624, y: 358, r: 10});
    _ctx = this.drawCircle(_ctx, {x: 778, y: 345, r: 10});

    return _ctx;
  }

  drawCircle = (_ctx, c) => {
      let ctx = _ctx
      ctx.beginPath();
      ctx.arc(c.x, c.y, c.r, 0, Math.PI * 2, false);
      ctx.closePath();
      ctx.fillStyle = "#FFFFFF55";
      ctx.fill();
      ctx.stroke();
      return ctx
  }

  handleMouseDown = (e) => {
    var mouseX = parseInt(e.clientX - this.state.offsetX);
    var mouseY = parseInt(e.clientY - this.state.offsetY);
    // this.state.isDown = true;
    // this.state.startX = mouseX;
    // this.state.startY = mouseY;

    this.setState({
      ...this.state,
      isDown: true,
      startX: mouseX,
      startY: mouseY,
    })
  }

  handleMouseMove = (e) => {
    if (!this.state.isDown) {
      return;
    }
    this.redrawStoredLines();
    let mouseX = parseInt(e.clientX - this.state.offsetX);
    let mouseY = parseInt(e.clientY - this.state.offsetY);
    // draw the current line
    let _ctx = this.state.ctx
    _ctx.beginPath();
    _ctx.moveTo(this.state.startX, this.state.startY);
    _ctx.lineTo(mouseX, mouseY);
    _ctx.stroke();

    this.setState({
      ...this.state,
      ctx: _ctx,
    })
  }

  handleMouseUp = (e) => {
    // this.state.isDown = false;
    let mouseX = parseInt(e.clientX - this.state.offsetX);
    let mouseY = parseInt(e.clientY - this.state.offsetY);
    let _storedLines = this.state.storedLines
    _storedLines.push({
      x1: this.state.startX,
      y1: this.state.startY,
      x2: mouseX,
      y2: mouseY
    });

    this.setState({
      ...this.state,
      isDown: false,
      storedLines: _storedLines,
    })

    console.log(_storedLines)

    // this.redrawStoredLines();
  }

  redrawStoredLines = () => {
    console.log(this.state.canvas)
    this.state.ctx.clearRect(0, 0, this.state.canvas.width, this.state.canvas.height);
    if (this.state.storedLines.length == 0) {
      return;
    }
    // redraw each stored line
    for (var i = 0; i < this.state.storedLines.length; i++) {
      this.state.ctx.beginPath();
      this.state.ctx.moveTo(this.state.storedLines[i].x1, this.state.storedLines[i].y1);
      this.state.ctx.lineTo(this.state.storedLines[i].x2, this.state.storedLines[i].y2);
      this.state.ctx.stroke();
    }
  }

  render() {
    return (
      <div className='homeToy'>
        {/*<div className="h4 bg-secondary text-white text-center p-2">
            { this.state.message }
        </div>*/}
        <div ref={this.myCanvasHolder} className="canvas-holder">
          <canvas 
            ref={this.myCanvas}
            id="canvas" width="1280" height="459" 
            onMouseDown={ this.handleEvent } 
            onMouseUp={ this.handleEvent }
            onMouseMove={ this.handleEvent }
          ></canvas>
        </div>

        <img className='temp-home-image' src="home-temp@4x.png" alt='Electronics' width="100%" />
      </div>
    )
  }
}

export default Hometoy;
