import React from 'react';

import './Portfolio.scss';


class Portfolio extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'Chris Yanc: Portfolio';
  }

  render() {
    return (
      <div className="Portfolio">

        <div className="ProjectGroup">Interactive Exhibits &amp; Displays</div>

        <div className="Project">
          <h2>Digital Graffiti Wall</h2>
        </div>


        <div className="Project">
          <h2>Ripley's Believe It or Not! Interactive Water Wall</h2>
        </div>


        <div className="Project">
          <h2>CMNH: Interactive Valcano Display</h2>
        </div>


        <div className="Project">
          <h2>CMNH: Earthquake Generator</h2>
        </div>


        <div className="Project">
          <h2>CMNH: Checkout Counter Components & Interactive</h2>
        </div>


        <div className="Project">
          <h2>Cleveland Clinic: Global Center for Health Innovation Displays</h2>
        </div>


        <div className="Project">
          <h2>Pizzav Inoteca: Order Wall</h2>
        </div>


        <div className="Project">
          <h2>Provenge: Interactive Kinect Tradeshow Display</h2>
        </div>


        <div className="Project">
          <h2>GE: HIMSS Tradeshow Touch Display</h2>
        </div>


        <div className="Project">
          <h2>Rogers Company: Gift Wrap Generator</h2>
          <span>2013</span>
        </div>


        <div className="Project">
          <h2>IngenuityFest Cleveland: Game of Life Audio Visualizer</h2>
        </div>


        <div className="Project">
          <h2>IngenuityFest Cleveland: 36 Views of a Bridge</h2>
          <span>2009</span>
        </div>

        <div className="ProjectGroup">Scout RFP Projects</div>

        <div className="Project">
          <h2>Inital App Design</h2>
        </div>

        <div className="Project">
          <h2>Inital App Design</h2>
        </div>

        <div className="Project">
          <h2>Worksheet Revision</h2>
        </div>

        <div className="Project">
          <h2>Dynamic Negotiation Assistant</h2>
        </div>
      </div>
      )
  }
}

export default Portfolio;
