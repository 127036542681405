import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import scoutone from './svg/Asset 21.svg'
import scoutoneline from './svg/Asset 22.svg'

function Scoutone() {
  return (
    <span className='scoutone'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
    		<img className='image' src={scoutone} alt="Scout RFP Logo" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={scoutoneline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                    <h3>Responsibilities</h3>
                    <span>Interactive Designer/Developer</span>
                    <ul>
                        <li>UX/UI Design</li>
                        <li>Front-end Development</li>
                        <li>Rapid Prototype Development</li>
                        <li>Usage Analytics</li>
                    </ul>
                    <h3>New Skills</h3>
                    <ul>
                        <li>Ruby on Rails</li>
                        <li>Angular 1.0</li>
                        <li>React</li>
                    </ul>
                </div>
            </div>
        </div>

    </span>
  );
}

export default Scoutone;
