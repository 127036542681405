import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import ripleys from './svg/Asset 13.svg'
import ripleysline from './svg/Asset 14.svg'

function Ripleys() {
  return (
    <span className='ripleys'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
            <h2>Ripley's Water <br />It Of Not!</h2>
            <span className='cyancdesign'>cyancdesign</span>
    		<img className='image' src={ripleys} alt="Ripley's Water It Of Not!" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={ripleysline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                   <iframe src="https://player.vimeo.com/video/25253869?autoplay=1&loop=1&byline=0&portrait=0&muted=1&background=1&autopause=false" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <h3>Tech Used</h3>
                    <ul>
                        <li>Front Screen Projection</li>
                        <li>Computer Vision Tracking</li>
                        <li>OpenFrameworks UX</li>
                        <li>Arduino Controlled UI</li>
                    </ul>
                </div>
                <i></i>
            </div>
        </div>
    </span>
  );
}

export default Ripleys;
