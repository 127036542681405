import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import onosysone from './svg/Asset 11.svg'
import onosysoneline from './svg/Asset 12.svg'

function Onosysone() {
  return (
    <span className='onosysone'>
    	<ScrollAnimation animateIn="fadeInLeft" delay="500">
    		<img className='image' src={onosysone} alt="Onosys Logo" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={onosysoneline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="right">
                <div class="text-content">
                    <h3>Responsibilities</h3>
                    <span>Interactive Designer</span>
                    <ul>
                        <li>App Design</li>
                        <li>Web & Print Design</li>
                        <li>Front-end Development</li>
                        <li>3rd Party Integration</li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
  );
}

export default Onosysone;
