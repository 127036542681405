import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  useRouteMatch
} from "react-router-dom";

import './App.scss';
import Timeline from './timeline/Timeline.jsx'
import Navigation from './Navigation.jsx'
import Hometoy from './homepage/Hometoy.jsx'
import Portfolio from './portfolio/Portfolio.jsx'


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: new Date()
    };
  }

  render() {
    return (
      <div className="App">
        <Router>
          <header className="App-header">
            <div className="container">
              <div>
                <h1>Chris Yanc</h1>
                <span>Interactive Designer & Developer</span>
              </div>

              <Navigation />
            </div>
          </header>

          <Switch>

            <Route path="/timeline.html">
              <Timeline />
            </Route>

            <Route path="/portfolio.html">
              <Portfolio />
            </Route>

            <Route exact path="/index.html">

              <Hometoy />

              <div className='container two-col'>
                <div className='left-col'>
                  <h2>About Me</h2>
                  <p><strong>Fail Thoughtfully, Learn Faster</strong><br />I push pixels, creative code, combine add-ons, manipulate the source. <br />Let's observe the trends but not be forced by them. <br />We'll tell stories, not just display some text. <br />So we can  emerse our senses to feel the emotion.</p>

                  <p>Hello, I'm Christopher Yanc<br />
                  Interactive Designer &amp; Developer. <br />
                  I focus on human-computer interaction design and development. With experience building multimedia experiences for business and art.
                  </p>
                </div>
                <div className='right-col'>
                  <div className='headshot'><img src="/headshot-yanc.jpg" alt="Chris Yanc Headshot" /></div>
                </div>
              </div>
            </Route>

            <Route exact path="/">
                <Redirect to="/index.html" />
            </Route>


          </Switch>

          <footer>
            <div className='container'>
            <span>&copy; {this.state.date.getFullYear()} | Chris Yanc (cyancdesign)</span>
            </div>
          </footer>
        </Router>
      </div>
    );
  }
}

export default App;
