import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import nuigroup from './svg/Asset 07.svg'
import nuigroupline from './svg/Asset 08.svg'

function Nuigroup() {
  return (
    <span className='nuigroup'>
    	<ScrollAnimation animateIn="fadeInLeft" delay="500">
            <span className='cyancdesign'>cyancdesign</span>
    		<img className='image' src={nuigroup} alt="Nuigroup" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={nuigroupline}  alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="right">
                <div class="text-content">
                <iframe src="https://player.vimeo.com/video/12603922?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&background=1&autopause=false" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <h3>Computer Vision UX/UI Community</h3>
                    <ul>
                        <li>Forum Discussions & Feedback</li>
                        <li>Actionscript 3 + Multitouch Tutorials</li>
                        <li>Writing Collaboration: <br/><em>Mastering OpenFrameworks: <br />Creative Coding Demystified</em></li>
                    </ul>
                </div>
                <i></i>
            </div>
        </div>
    </span>
  );
}

export default Nuigroup;
