import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import optiem from './svg/Asset 03.svg'
import optiemline from './svg/Asset 04.svg'

function Optiem() {
  return (
    <span className='optiem'>
    	<ScrollAnimation animateIn="fadeInLeft" delay="500">
    		<img className='image' src={optiem} alt="Optiem Logo" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={optiemline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="right">
                <div class="text-content">
                    <h3>Responsibilities</h3>
                    <span>Web Designer</span>
                    <ul>
                        <li>Website &amp; Email Design</li>
                        <li>Front-end Development</li>
                        <li>Logo &amp; Corporate Identity Design</li>
                    </ul>
                    <h3>Skills</h3>
                    <ul>
                        <li>CSS Website Layout</li>
                        <li>Javascript &amp; Actionscript Programming</li>
                        <li>CMS Integration &amp; Wordpress Template</li>
                        <li>Design Presentation &amp; Client Communication</li>
                    </ul>
                </div>
            </div>
        </div>

    </span>
  );
}

export default Optiem;
