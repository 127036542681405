import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import onosystwo from './svg/Asset 17.svg'
import onosystwoline from './svg/Asset 18.svg'

function Onosystwo() {
  return (
    <span className='onosystwo'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
    		<img className='image' src={onosystwo} alt="Onosys a LivingSocial Company Logo" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={onosystwoline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                    <h3>Responsibilities</h3>
                    <span>Interactive Designer</span>
                    <ul>
                        <li>Front-end Development</li>
                        <li>Web & Print Design</li>
                        <li>Internal Product Tools</li>
                        <li>App Design</li>
                    </ul>
                </div>
            </div>
        </div>

    </span>
  );
}

export default Onosystwo;
