import './Timeline.scss';
import mainline from './svg/Asset 27.svg'

function MainLine() {
  return (
    <img className='mainline' src={mainline} alt="Main Timeline Line" />
  );
}

export default MainLine;
