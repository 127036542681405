import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import health from './svg/Asset 19.svg'
import healthline from './svg/Asset 20.svg'

function Health() {
  return (
    <span className='health'>
    	<ScrollAnimation animateIn="fadeInLeft" delay="500">
            <h2>Global Center for Health Innovation Cleveland Clinic</h2>
            <span className='cyancdesign'>cyancdesign</span>
    		<img className='image' src={health} alt="Global Center for Health Innovation Cleveland Clinic" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={healthline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="right">
                <div class="text-content">
                    <h3>Display Concepts</h3>
                    <ul>
                        <li>Patient Journey Wall</li>
                        <li>Proximity Activate Displays</li>
                        <li>Direct Nurse Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
  );
}

export default Health;
