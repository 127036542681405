import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import scouttwo from './svg/Asset 23.svg'
import scouttwoline from './svg/Asset 24.svg'

function Scouttwo() {
  return (
    <span className='scouttwo'>
    	<ScrollAnimation animateIn="fadeInLeft" delay="500">
    		<img className='image' src={scouttwo} alt="Scout RFP a Workday Company Logo" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={scouttwoline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="right">
                <div class="text-content">
                    <h3>Responsibilities</h3>
                    <span>Senior UX Designer</span>
                    <ul>
                        <li>Product Design</li>
                        <li>Rapid Prototype Development</li>
                        <li>Design System Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
  );
}

export default Scouttwo;
