import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import graffiti from './svg/Asset 09.svg'
import graffitiline from './svg/Asset 10.svg'

function Graffiti() {
  return (
    <span className='graffiti'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
            <h2>Digital Graffiti Wall</h2>
            <span className='cyancdesign'>cyancdesign</span>
    		<img className='image' src={graffiti} alt="Digital Graffiti Wall" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={graffitiline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                    <iframe src="https://player.vimeo.com/video/15183613?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&background=1&autopause=false" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    <h3>Tech Used</h3>
                    <ul>
                        <li>Computer Vision Tracking</li>
                        <li>Actionscript 3</li>
                        <li>OpenFrameworks</li>
                    </ul>

                    <h3>Events Attended</h3>
                    <ul>
                        <li>IngenuityFest Cleveland</li>
                        <li>Kringle's Inventionasium Experience</li>
                        <li>Mentor CityFest</li>
                        <li>Loop in D.C.</li>
                        <li>Yelp Cleveland Party</li>
                    </ul>
                </div>
                <i></i>
            </div>
        </div>

    </span>
  );
}

export default Graffiti;
