import {
  Link,
  useRouteMatch
} from "react-router-dom";

import './App.scss';

function Navigation() {
 let match = useRouteMatch({
    exact: true
  });

  return (
    <nav className='Navigation'>
      <ul>
        <li className={useRouteMatch({path: "/index.html", strict: true, isExact: true}) ? "active" : ""}><Link to="/index.html">Home</Link></li>
        <li className={useRouteMatch({path: "/timeline.html"}) ? "active" : ""}><Link to="/timeline.html">Timeline</Link></li>
        {/*<li className={useRouteMatch({path: "/portfolio.html"}) ? "active" : ""}><Link to="/portfolio.html">Portfolio</Link></li>*/}
        <li><a target="_blank" href="Christopher Yanc Resume.pdf">Resume</a></li>
        {/*<li><Link to="/">ResumeLink/a></li>*/}
      </ul>
    </nav>
  );
}

export default Navigation;
