import './Timeline.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import learning from './svg/Asset 05.svg'
import learningline from './svg/Asset 06.svg'

function Learning() {
  return (
    <div className='learning'>
    	<ScrollAnimation animateIn="fadeInRight" delay="500">
    		<img className='image' src={learning} alt="Computer" />
    	</ScrollAnimation>
    	<ScrollAnimation animateIn="fadeIn" className="line-container">
    		<img className='line' src={learningline} alt="Connector Line" />
    	</ScrollAnimation>

        <div class="tooltip">
            
            <div class="left">
                <div class="text-content">
                    <h3>Self-taught</h3>
                    <ul>
                        <li>Arduino Prototyping</li>
                        <li>C++ &amp; openFrameworks</li>
                        <li>Actionscript 3</li>
                        <li>MySQL & PHP Development</li>
                        <li>3D Design &amp; Animation</li>
                        <li>Computer Vision UX</li>
                    </ul>
                </div>
                <i></i>
            </div>
        </div>
    </div>
  );
}

export default Learning;
